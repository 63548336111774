.toolbar {
    margin: 30px 0;

    // TODO:
    // https://css-tricks.com/flexbox-like-just-put-elements-in-a-row-with-css-grid/
    // Make buttons take a smaller space

    .content {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        justify-content:space-evenly;
        padding: 15px;

        .fullButton {
            text-align: center;
            height: 100%;
            & button {
                width: 80%;
                padding: 15px;
            }
        }
        .full > div {
            height: 100%;
            width: 100%;
            margin-bottom: 5px;
        }
        div {
            svg {
                height: 100%;
                width: 40px;
                margin-left: 20px;
                margin-right: 20px;
                flex: 0 0 10%;
                &:hover {
                    border: 1px solid black;
                }
            }
        }
        .lineBreak {
            width:100%;
        }

        .dropdown {
            width:100%;
        }
    }
}