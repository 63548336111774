.bottomNavPaginator {
    /* top: ; */
    bottom: 20%;    
}

.pagination {
	text-align: center;
	background: rgba(65, 65, 65, 0.527);
	border-radius: 5px;
	padding: 10px;
    user-select: none;
}
.pagination > li {
	display: inline-block;
    padding-left: 0;
	height: 100%;
    color: black;
}
.pagination > li > a,
.pagination > li > span {
	position: relative;
    float: left;
    padding: 6px 12px;
    text-decoration: none;
    background-color: rgb(219, 219, 219);
    border-radius: 50%;
    margin: 2px;
    width: 32px;
    height: 32px;
    line-height: 20px;
    vertical-align: middle;
    text-align: center;
    outline: none;
}

/* This is for the page indicator that we're currently on */
.pagination>li.active>a {
	color: #fff;
    background-color: #181818;
    border-color: #414642;
}

/* Hover styles for all interactable buttons */
.pagination > li > a:hover {
	background-color:  #1b1515;
    color: white;
}

/* Previous button styling */
.pagination > li:first-child > a,
.pagination > li:first-child > span {
	margin-left: 0;
    margin-right: 15px;
    background-color: transparent;
    color: white;
    outline: none;
    cursor: pointer;
}

/* Next button styling */
.pagination > li:last-child > a,
.pagination > li:last-child > span {
	margin-right: 0;
    background-color: transparent;
    color: white;
    outline: none;
    cursor: pointer;
}