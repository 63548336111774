.toolbar {
    margin: 30px 0;
    width: 100%;
    display: inline-block;
    .content {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        justify-content: space-evenly;
        padding: 15px;

        .grid {
            align-items: center;
        }
        .full {
            width: 100%;
            // margin-bottom: 5px;
        }
        .full > div {
            width: 100%;
            // margin-bottom: 5px;
        }
        .lineBreak {
            width:100%;
        }
        .dropdown {
            width:100%;
            text-align: center;
        }
    }
}

