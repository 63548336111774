.body {
    width: 80%;
    max-width: 1700px;
    margin: auto;
}
.profileAvatar {
    width: 150px;
    height: 150px;
    // border-radius: 50%;
    object-fit: cover;
    border: 5px double rgba(189,189,189,0.7);
    box-shadow: 0px 0px 12px 4px rgba(152,152,152,0.59);
    position: absolute;
    left: 20px;
    bottom: -25px;
    background-color: white;
}

.profileCover {
    position: relative;
}

.profileBgImg {
    width: 100%;
    max-width: 1700px;
    height: 400px;
    object-fit: cover;
}

.username {
    position: absolute;
    left: 200px;
    font-weight: 500;
}

.editProfileBtn {
    position: absolute;
    left: 200px;
    bottom: 60px;
}

.about {
    border: 3px double rgba(206, 206, 206, 0.65);
    border-radius: 4px;
    background-color: white;
    overflow-wrap: break-word;
}

.resume {
    border: 3px double rgba(206, 206, 206, 0.65);
    border-top: 0px;
    border-radius: 4px;
    background-color: white;
    padding: 20px;
}

.appbar {
    border: 3px double rgba(206, 206, 206, 0.65);
    border-bottom: 1px solid rgba(206, 206, 206, 0.65);
    box-shadow: none;
}

.attributeTitle {
    margin-top: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid gainsboro;
    padding-bottom: 5px;
}