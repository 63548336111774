.fullWidth {
    max-width: 500px;
    margin: 0 auto;
    display: block;
    padding-bottom: 20px;
}

.window {
    max-width: 500px;
    background-color: #fafafa;
    background-color: #fff;
    outline: none;
    // box-shadow: 0px 0px 40px 1px rgba(158, 159, 204, 0.8);
    border-radius: 10px;
    margin: 0 auto;

    .title {
        text-align: center;
    }

    .message {
        text-align: center;
    }

    .textGroup {
        width: 67%;
        margin: 0 auto;
        .nameBox {
            width: 100%;
            margin-bottom: 10px;
        }
        .emailBox {
            width: 100%;
            margin-bottom: 10px;
        }
        .passwordBox {
            width: 100%;
            margin-bottom: 10px;
        }
        .passCheckBox {
            width: 100%;
            margin-bottom: 20px;
        }
    }

    .googleButton {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 20px;
        border-radius: 100px;
    }

    .buttonGroup {
        width: 50%;
        margin: 10px auto;
        margin-bottom: 10px;
        .cancelButton {
            width: 100%;
            margin-left: -20px;
            border-radius: 100px;
        }
        .loginButton {
            width: 100%;
            margin-left: 20px;
            border-radius: 100px;
        }
        .registerButton {
            width: 100%;
            margin-left: 20px;
            border-radius: 100px;
        }
    }
}

.register {
    text-align: center;
    font-size: larger;
}

.modalTitle {
    width: 100%;
    text-align: center;
}

.buttonGroup {
    text-align: center;
    input,
    button {
        margin: 10px;
    }
}

.inputGroup {
    .blockFields {
        width: 95%;
        margin: 15px;
    }
}
