.restricted-container {
  background-color: #020233!important;
  padding: 20px;
}

.not-found {
  width: 560px;
  height: 225px;
  margin-right: -10px;
  margin-top: 20px;
}
.starry-sky {
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;

}

.notfound-copy {
  color: #FFF;
  position: fixed;
  top: 25px;
  right: 10%;
  text-align: right;
}

/* change to alternating star opacities */
.all-stars {
  animation: blinkblink 7s linear infinite;
}

@keyframes blinkblink {  
  50% { opacity: 0.1; }
}