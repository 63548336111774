.jobTitle {
    margin: auto;
    font-size: 12pt;
    text-align: left;
}   

.jobDate {
    margin: auto;
    text-align: right;
}   
