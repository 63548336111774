p {
	font-family: Arial;
}

hr {
	color: grey;
}

// main {
// 	padding: 0px 30px;
// }

.mainTitle {
	margin-left: 5px;
	font-weight: bold;
	font-size: 25pt;
}

.iconListItem {
	display: flex;
	padding: 3px 0px;
}

.iconListItem label {
	padding-left: 10px;
}


.iconLabelSet {
	display: flex;
	align-items: center;
	padding-bottom: 20px;
}

.iconLabelSet :hover {
	cursor: pointer;
}

.iconSet svg {
	margin: 3px;
}

h4 {
	margin-bottom: 0px;
}
