$height: 40px;

.button {
    height: $height;
    text-align: center;
    position: relative;
    background: #8894c8;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #71e7d1, #acb6e5);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #a0d3c2, #8899e4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    border: none;
    outline: none;
    border-radius: 5px;
    display: block;
    margin: 0 auto;
    cursor: pointer;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2), 0 3px 3px rgba(0, 0, 0, 0.25);
    transition: all 0.15s ease-in-out;
    
    .logo {
        position: absolute;
        left: 0;
        height: $height - 3;
        width: $height - 3;
        // border: 1px solid black;
        
        img {
            top: 20%;
            height: 20px;
            width: 20px;
            vertical-align: middle;
        }
    }
    .text {
        // font-size: 10px;
        // line-height: 20px;
        // vertical-align: middle;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        margin-left: $height;
    }

    &:hover {
        transform: scale(1.05);
        transition-duration: 0.15s;
    }
}

.frame {
    height: 50px;      /* equals max image height */
    border: 1px solid red;
    white-space: nowrap;
    
    text-align: center; margin: 1em 0;
}

.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.centeredImg {
    background: #3A6F9A;
    vertical-align: middle;
    max-height: 25px;
}
