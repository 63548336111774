.title {
    font-size: 50px;
    margin-bottom: -10px;
}

.welcome {
    font-size: 30px;
}

.container {
    width: 100%;

    .bodyText {
        width: 50%;
        font-size: 15px;
        text-align: justify;
    }

    .image {
        width: 50%;
        float: right;
        margin-left: 50px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }
}

.media {
    text-align: right;
}

.headerImage {
    width: 100%;
}

.parallax {
    /* The image used */
    background-image: url('https://images.unsplash.com/photo-1462206092226-f46025ffe607?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1506&q=80');

    /* Set a specific height */
    min-height: 500px;
    background-color: red;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
