.card {
  margin: 0.5rem 1rem;
  // background: url("https://images.unsplash.com/photo-1604079628040-94301bb21b91?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80");

  .content {
    text-align: center;
  }
}

.field {
  font-weight: 800;
}

.spaced_container {
  display: flex;
  justify-content: space-between;
}
