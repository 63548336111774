.container {
    width: 100%;
    margin: 0 auto;
    text-align: center;

    .vote {
        display: inline-block;
        background-color: rgba(224, 224, 224, 0.856);
        border-radius: 50%;
        height: 30px;
        width: 30px;
        vertical-align: middle;
        line-height: 30px;
        font-weight: bold;
        margin-bottom: 6px;
    
        .positive {
            color: rgb(0, 202, 0);
        }

        .neutral {
            color: rgb(66, 66, 66);
        }
    
        .negative {
            color: #dd1a1a;
        }
    }

    .arrow {
        cursor: pointer;
        transition: all 0.15s ease-in-out;

        &:hover {
            transform: scale(1.5);
            transition-duration: 0.15s;
        }
        
    }
    .upvoted {
        background-color: rgba(122, 172, 7, 0.541);
        border-radius: 50%;
    }
    .downvoted {
        background-color: rgba(255, 36, 36, 0.541);
        border-radius: 50%;
    }
}