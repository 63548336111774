.button {
    width: 100%;
    height: 100%;
    display: block;
}

.newJobForm {
    .field {
        margin: 10px;
    }  
}
