.dropdown {
    div {
        width: 100%;
    }
    
}
.fieldTitle {
    text-align: center;
    margin: 0px;
}

.jobList {
    margin-bottom: 100px;
}

.fields {
    display: flex;
    flex-direction: row;
}

.root {
    margin-top: 20px;
}