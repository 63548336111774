.rightCorner {
    float: right;
    position: absolute;
    right: 0;
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.2);
        transition-duration: 0.2s;
    }
}