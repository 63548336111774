.boardInfo {
    text-align: center;
    position: relative;
    min-height: 400px;

    .boardImage {
        margin-top: 10px;
        height: 400px;
        overflow: hidden;
        background-color: rgb(42, 42, 42);

        img {
            width: 100%;
        }
    }

    .boardInfoTextSection {
        position: absolute;
        width: 100%; 
        top: 20%;
        color: white;
        background-color: rgba(0, 0, 0, 0.75);
        padding: 15px 0;
        overflow: hidden;
    }
}

.divider {
    width: 50%;
}

.footerPadding {
    margin-top: 100px;
}


.boardEditForm {
    margin-bottom: 20px;
        
    .boardNameField {
        margin: 20px 0;
    }
    .boardImageURLField {
        margin: 20px 0;
    }
}
