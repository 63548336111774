
.boardCardGrid {
    .boardCard {
        margin: 7px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
        text-align: center;
        transition: all 0.2s ease-in-out;
        position: relative;

        &:hover {
            transform: scale(1.05);
            transition-duration: 0.2s;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
        }
        
        .viewButton {
            margin-top: 20px;
            transition: all 0.15s ease-in-out;
            &:hover {
                transform: scale(1.05);
                transition-duration: 0.15s;
            }
        }
    }
}
