.comment {
    margin: 10px;
    padding: 15px;
    position: relative;
    
    .avatar {
        margin-bottom: 20px;
        transition: all 0.2s ease-in-out;
        &:hover {
            transform: scale(1.2);
            transition-duration: 0.2s;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 5px;
            cursor: pointer;
        }
    }
    
    .username {
        display: inline;
        margin: 0;
        text-align: left;
        color: black;
        transition: all 0.1s ease-in-out;
        
        &:hover {
            color: blueviolet;
            transition-duration: 0.1s;   
        }
    }
    
    .userID {
        color: gray;
        display: block;
        font-size: 65%;
    }
    
    .commentBox {
        border-left: 3px solid rgba(128, 128, 128, 0.25);
        margin: 10px;
        padding-left: 10px;
    }

    .optionsMenu {
        position: absolute;
        right: 0;    
    }

    .postedTime {
        text-align: left;
        color: gray;
    }
}