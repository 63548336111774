.dropdowns {
    // height: 1000px;
    padding: 10px;
    margin: 10px;
}

.link {
    color: blueviolet;
}

.buttonGroup {
    max-width: 250px;
    margin: 0 auto;

    .buttonContainer {
        text-align: center;
    }
}
